import { range } from 'lodash'

import { getHomepageBatch, getPromoBox } from 'data/api'
import { isResponseError, isResponseSuccessful } from 'libs/utils/api'
import { ResponseError } from 'types/api'
import { HomepageBlocksDto } from 'types/dtos'
import { ResponseCode } from 'data/api/response-codes'

import { FetchHomepageBlocksOptions } from '../types'
import { logHomeMessage } from './observability'

export const EMPTY_RESPONSE_ERROR: ResponseError = {
  status: 200,
  code: ResponseCode.Ok,
  message: 'empty',
  errors: [],
  exception: null,
}

export const getParallelHomepageBlocks = async (
  options: FetchHomepageBlocksOptions,
): Promise<HomepageBlocksDto | ResponseError<unknown>> => {
  const { homepageSessionId, tab } = options
  const batchNumbers = range(1, tab.batchCount + 1)

  const promoBoxPromise = tab.isPromoBoxEnabled ? getPromoBox() : null
  const batchPromises = batchNumbers.map(number =>
    getHomepageBatch(number, {
      tabName: tab.name,
      homepageSessionId,
    }),
  )
  const [promoBoxResponse, ...batches] = await Promise.all([promoBoxPromise, ...batchPromises])
  const promoBox =
    promoBoxResponse && isResponseSuccessful(promoBoxResponse) ? promoBoxResponse.promo_box : null
  const blocks = batches
    .filter(isResponseSuccessful)
    .map(result => result.blocks)
    .flat()

  if (blocks.length === 0) {
    const error = batches.find(isResponseError)
    const errorMessage = error ? error.message : EMPTY_RESPONSE_ERROR.message

    logHomeMessage(`Blocks error: ${errorMessage}`, `tab: ${options.tab.name}`)

    return error ?? EMPTY_RESPONSE_ERROR
  }

  return {
    promo_box: promoBox,
    blocks,
  }
}
