import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useTracking from 'hooks/useTracking'
import useLocation from 'hooks/useLocation'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import { setBannerAsSeen } from 'data/api'
import { MultiVariantsOnboardingModalModel, OnboardingModalModel } from 'types/models'
import { clickEvent, viewEvent, viewScreenEvent } from 'libs/common/event-tracker/events'
import { actions } from 'state/banners/slice'
import { removeParamsFromQuery, urlWithParams } from 'libs/utils/url'
import * as selectors from 'state/banners/selectors'

type Props = {
  defaultBanner: OnboardingModalModel
  multiVariantsBanner?: MultiVariantsOnboardingModalModel
}

const useOnboarding = ({ defaultBanner, multiVariantsBanner }: Props) => {
  const banner = multiVariantsBanner || defaultBanner
  const { track } = useTracking()
  const banners = useSelector(selectors.getBanners)
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(true)
  const [slide, setSlide] = useState(0)
  const isOnboardingModalStatusParamFSEnabled = useFeatureSwitch(
    'onboarding_modal_status_param_enabled',
  )
  const { replaceHistoryState, relativeUrl, urlQuery, searchParams } = useLocation()

  const slideName = banner.steps[slide]?.name
  const bannerType = multiVariantsBanner ? 'multi_variants_onboarding_modal' : 'onboarding_modal'

  useEffect(() => {
    setBannerAsSeen({ type: bannerType, name: banner.name })
  }, [bannerType, banner.name])

  useEffect(() => {
    track(viewScreenEvent({ screen: banner.name }))
  }, [banner.name, track])

  useEffect(() => {
    if (!isOnboardingModalStatusParamFSEnabled) return
    if (!isOpen) return
    if (searchParams.onboarding_modal_status) return

    const updatedUrl = urlWithParams(relativeUrl, {
      ...searchParams,
      onboarding_modal_status: 'shown',
    })

    replaceHistoryState(updatedUrl)
  }, [
    isOpen,
    replaceHistoryState,
    relativeUrl,
    searchParams,
    isOnboardingModalStatusParamFSEnabled,
  ])

  useEffect(() => {
    if (!slideName) return

    track(
      viewEvent({
        screen: banner.name,
        target: ViewableElement.OnboardingModalCard,
        targetDetails: slideName,
      }),
    )
  }, [banner.name, slideName, track])

  const handleClose = () => {
    if (isOnboardingModalStatusParamFSEnabled) {
      const urlWithoutParam = removeParamsFromQuery(relativeUrl, urlQuery, [
        'onboarding_modal_status',
      ])
      replaceHistoryState(urlWithoutParam)
    }

    setIsOpen(false)

    dispatch(
      actions.setBanners({
        banners: {
          ...banners,
          onboardingModal: {
            ...banner,
            isClosed: true,
          },
        },
      }),
    )
  }

  const handleModalClose = () => {
    track(
      clickEvent({
        screen: banner.name,
        target: ClickableElement.CloseScreen,
        targetDetails: slideName,
      }),
    )

    handleClose()
  }

  const handlePrimaryClick = () => {
    track(
      clickEvent({
        screen: banner.name,
        target: ClickableElement.UploadItem,
        targetDetails: slideName,
      }),
    )
  }

  return {
    isOpen,
    onModalClose: handleModalClose,
    onPrimaryClick: handlePrimaryClick,
    setSlide,
  }
}

export default useOnboarding
