import { useEffect, useRef } from 'react'

import { transformHomepageBlockResponse } from 'data/api/transformers/response'
import useFetch from 'hooks/useFetch'

import { getParallelHomepageBlocks } from '../utils/api'
import useTabs from './useTabs'
import { useHomeContext } from '../HomeProvider'

const useFetchHomepageBlocks = () => {
  const { currentTabName, tabs } = useTabs()
  const { homepageSessionId } = useHomeContext()
  const initialTabName = useRef(currentTabName)

  const {
    fetch: fetchHomepageBlocks,
    transformedData: blocks,
    isLoading,
    error,
  } = useFetch(getParallelHomepageBlocks, transformHomepageBlockResponse, {
    clearDataOnFetch: true,
  })

  useEffect(() => {
    // It is validated before that the first tab exists
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentTab = tabs.find(tab => tab.name === initialTabName.current) || tabs[0]!

    fetchHomepageBlocks({
      tab: currentTab,
      homepageSessionId,
    })
  }, [fetchHomepageBlocks, tabs, homepageSessionId])

  return {
    blocks: error ? undefined : blocks,
    refetchBlocks: fetchHomepageBlocks,
    areBlocksLoading: (!blocks && !error) || isLoading,
    error,
  }
}

export default useFetchHomepageBlocks
