'use client'

import { useCallback, useState } from 'react'
import { Button, Card, Cell, Image, Spacer, Text } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useTracking from 'hooks/useTracking'
import { userClickHomepageElement } from 'libs/common/event-tracker/events'
import { ItemsCardModel, PhotoModel, ProductItemModel } from 'types/models'
import { Screen } from 'constants/tracking/screens'

import { getHomepageShuffleCardSuggestions } from 'data/api'
import { transformHomepageItemDtosToProductItems } from 'data/transformers/product-item'

import CardItems from './CardItems'

type CardTitleProps = {
  header: {
    photo: PhotoModel
    title: string
    subtitle: string
  }
}

const CardTitle = ({ header }: CardTitleProps) => {
  return (
    <Cell
      body={
        <div className="items-card-header" aria-hidden="true">
          <Text type={Text.Type.Subtitle} theme="amplified" text={header.title} truncate />
          <Text type={Text.Type.Subtitle} theme="muted" text={header.subtitle} truncate />
        </div>
      }
      prefix={
        <Image
          ratio={Image.Ratio.Square}
          size={Image.Size.Large}
          styling={Image.Styling.Rounded}
          src={header.photo.url}
          aria={{
            'aria-hidden': true,
          }}
        />
      }
      styling={Cell.Styling.Tight}
      tabIndex={0}
      aria={{
        'aria-label': `${header.title} ${header.subtitle}`,
      }}
    />
  )
}

type Props = {
  card: ItemsCardModel
  homepageSessionId: string
  blockName: string
  index: number
}

const SuggestionsCard = ({ card, homepageSessionId, blockName, index }: Props) => {
  const { track } = useTracking()

  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState<Array<ProductItemModel>>(card.items)
  const asset = useAsset('/assets/icons')

  const trackCardClick = useCallback(() => {
    track(
      userClickHomepageElement({
        blockName,
        position: index + 1,
        contentSource: card.contentSource,
        contentSourceLabel: card.action?.type || '',
        contentSourceId: card.id,
        homepageSessionId,
        screen: Screen.NewsFeed,
      }),
    )
  }, [track, blockName, index, card.contentSource, card.id, homepageSessionId, card.action?.type])

  const shuffleSuggestions = useCallback(
    async (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      if (isLoading) {
        event.preventDefault()

        return
      }

      setIsLoading(true)
      trackCardClick()

      const response = await getHomepageShuffleCardSuggestions({ cardId: card.id, blockName })

      if ('errors' in response || !card.items.length) {
        setIsLoading(false)
        setItems(card.items)

        return
      }

      const transformedSuggestions = transformHomepageItemDtosToProductItems(response.items)

      setIsLoading(false)
      setItems(transformedSuggestions)
    },
    [setIsLoading, setItems, card, blockName, trackCardClick, isLoading],
  )

  return (
    <Card>
      <Cell
        id="items-card-cell"
        body={
          <div>
            <CardTitle header={card.header} />
            <Spacer size={Spacer.Size.Large} />
            <CardItems items={items} homepageSessionId={homepageSessionId} />
            {card.action && (
              <>
                <Spacer size={Spacer.Size.Large} />
                {card.action.type === 'cta' && (
                  <Button
                    theme="primary"
                    text={
                      <Text
                        type={Text.Type.Title}
                        text={card.action.title}
                        theme="primary"
                        as="span"
                      />
                    }
                    testId="items-cards-cta-button"
                    url={card.action.url || undefined}
                    onClick={trackCardClick}
                    truncated
                  />
                )}
                {card.action.type === 'shuffle' && (
                  <Button
                    onClick={shuffleSuggestions}
                    theme="primary"
                    icon={<Image src={asset('shuffle.svg')} size={Image.Size.Regular} />}
                    text={
                      <Text
                        type={Text.Type.Title}
                        text={card.action.title}
                        theme="primary"
                        as="span"
                      />
                    }
                    testId="items-cards-shuffle-button"
                    disabled={isLoading}
                    isLoading={isLoading}
                    truncated
                  />
                )}
              </>
            )}
          </div>
        }
      />
    </Card>
  )
}

export default SuggestionsCard
